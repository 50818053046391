  .register {
    overflow: hidden;
  }
  .leftBar{
    width: 640px;
    height: 100vh;
    color: #fff;
  }
  .leftBar__logo{
    width: 361px;
    height: 62px;
    margin: 100px 140px 32px 140px;
  }
  .leftBar__grid {
    margin: 40px 0px;
  }
  .leftBar__gridItem{
    width: 160px;
    height: 160px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .leftBar__gridItem__image {
    margin-top: 10px;
    width: 80px;
    height: 80px;
  }
  .leftBar__gridItem__text {
    font-size: 16px;
    font-family: inter;
    font-weight: 500;
    margin-top: 19px;
  }
  .leftBar__signinText {
    font-family: inter;
    margin: 0px 142px
  }
  .leftBar__button {
    margin-top: 182px;
    font-size: 14px;
    font-family: inter;
  }
  .form {
    width: 45vw;
    margin: 180px 0px 0px 100px;
  }
  .register_title{
    margin: 10px 100px 0px 0px;
  font-family:"fira sans,sans-serif";
  }
  .register_title:last-child {
    margin-right: 0px;
  }
  .phone_number_input {
    width: 100%;
    height: 40px;
    margin-top: 40px;
    border: 1px solid #2E519E;
    border-radius: 5px;
    padding-left: 15px;
  }
  .phone_number_input input {
    width: 100%;
    border: none;
    outline: none;
  }
  .phone_number_input input::placeholder {
    color: #656D78;
  }
  @media all and (min-width:1920px) {
    .form {
      width:45vw
    }

  }
  @media all and (max-width:1900px) {
    .leftBar{
      width: 540px;
      height: 100vh;
      color: #fff;
    }
    .leftBar__logo{
      width: 281px;
      height: 52px;
      margin: 100px 100px 24px 100px;
    }
    .leftBar__grid {
      margin: 30px 0px;
    }
    .leftBar__gridItem{
      width: 130px;
      height: 130px;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 15px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    .leftBar__gridItem__image {
      width: 60px;
      height: 60px;
    }
    .leftBar__gridItem__text {
      font-size: 14px;
      font-family: inter;
      font-weight: 500;
      margin-top: 19px;
    }
    .leftBar__signinText {
      font-family: inter;
      margin: 0px 102px
    }
    .leftBar__button {
      margin-top: 182px;
      font-size: 14px;
      font-family: inter;
    }
  }
  @media all and (max-width:1440px) {
    .leftBar{
      width: 400px;
      height: 100vh;
      color: #fff;
    }
    .leftBar__logo{
      width: 240px;
      height: 50px;
      margin: 60px 80px 20px 80px;
    }
    .leftBar__grid {
      margin: 20px 0px;
    }
    .leftBar__gridItem{
      width: 110px;
      height: 110px;
      border: 1px solid #FFFFFF;
      box-sizing: border-box;
      border-radius: 15px;
      align-items: center;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }
    .leftBar__gridItem__image {
      width: 50px;
      height: 50px;
    }
    .leftBar__gridItem__text {
      font-size: 12px;
      font-family: inter;
      font-weight: 500;
      margin-top: 10px;
    }
    .leftBar__signinText {
      font-family: inter;
      margin: 0px 88px;
      font-size: 12px;
    }
    .leftBar__button {
      margin-top: 72px;
      font-size: 2px;
      font-family: inter;
    }
    .form {
      width: 45vw;
      margin: 100px 0px 0px 100px;
    }
    .register_title{
      margin: 10px 70px 0px 0px;
      font-family:"fira sans,sans-serif";
      font-size: 12px;
    }
    .register_title:last-child {
      margin-right: 0px;
    }
    .phone_number_input {
      width: 100%;
      height: 30px;
      margin-top: 25px;
      border: 1px solid #2E519E;
      border-radius: 5px;
      padding-left: 15px;
    }
    .phone_number_input input {
      width: 100%;
      border: none;
      outline: none;
    }
    .phone_number_input input::placeholder {
      color: #656D78;
    }
  }
  @media all and (max-width:986px) {
    .leftBar {
      width: 100vw;
    }
    .form {
      width: 100vw;
      margin: 70px 0px 0px 0px;
      padding: 0px 30px;
      align-items: center;
      justify-content: center;
    }
  }
  @media all and (max-width:469px) {
    .form {
      width: 100vw;
      margin: 70px 0px 0px 0px;
      padding: 0px 30px;
      align-items: center;
      justify-content: center;
    }
    .register_title{
      margin: 10px 25px 0px 0px;
      font-family:"fira sans,sans-serif";
      font-size: 10px;
    }
  }
  @media (max-height:768px) {
  .leftBar {
  height: 100%;
  min-height: 100%
  }
  }

