label {
  display: none;
}

.project__details {
  width: 57.5vw;
}

.team__info {
  width: 25vw;
}

@media screen and (min-width: 2561px) {
  .team__info__userinfo {
    /* overflow: hidden; */
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    max-width: 200px;
    font-family: inter;
  }
}

@media all and (max-width: 2560px) {
  .css-eo57y8 {
    height: 75px;
    align-items: center;
  }
  .team__info__userinfo {
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    max-width: 200px;
    font-family: inter;
  }
}
@media all and (min-width: 1920px) {
  /* .css-eo57y8 {
    height: 70px;
    align-items: center;
  } */
  /* .team__info__userinfo {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    max-width: 200px;
    font-family: inter;
  } */
}

@media screen and (max-width: 1900px) {
  .project__details {
    width: 56vw;
  }

  .team__info {
    width: 23vw;
  }
}

@media screen and (max-width: 1600px) {
  .project__details {
    width: 52vw;
  }

  .team__info {
    width: 24vw;
  }
}

@media screen and (max-width: 1366px) {
  .project__details {
    width: 50vw;
  }

  .team__info {
    width: 24vw;
  }
}

@media (max-width: 1536px) {
  .css-eo57y8 {
    height: 60px;
  }
  .team__info__userinfo {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 10px;
    max-width: 100px;
    font-family: inter;
  }
}

/* @media screen and (min-width: 1280px) {
  .project__details {
    width: 37.5vw;
  }

  .team__info {
    width: 25vw;
  }
} */
