html,
body {
  max-width: 100%;
  overflow-x: hidden;
}
.header {
  background-color: #3963c3;
  color: white;
  border-bottom: 1px solid #ffffff50;
}
.head-btn Button {
  margin-left: 10px;
  border: 0.5px solid white;
}
.adjustmentbar {
  background: #3963c3;
}
.changeslide Button {
  border: 0.3px solid #ffffff;
}
.annotate p {
  font-size: small;
  text-align: center;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.dashboard {
  width: 100%;
}

.dashboard__menu {
  width: 8.33333%;
}

.dashboard__body {
  width: fit-content;
}

.dashboard__menu__list {
  font-size: 0.875rem;
  font-family: "inter";
  font-weight: 400;
}

.dashboard__project__progressbar {
  font-family: "fira sans";
  font-weight: 500;
}

.recently__viewed {
  width: 85vw;
  height: 75.5555vh;
  margin-left: 1.5em;
}

.recently__viewed__reader {
  width: 58.33333vw;
  height: 80vh;
  margin-left: 1.5em;
}

.last__reports {
  width: 25vw;
  height: 40vh;
}

.last__task {
  width: 25vw;
  height: 39vh;
}

.last__task__img {
  height: 36vh;
  width: 23vw;
  margin-bottom: 0.5em;
}

@media screen and (min-width: 2560px) {
  .dashboard__menu {
    width: 10em;
  }
  /* .recently__viewed__reader {
    width: 90em;
    height: 79.9em;
    margin-left: 3em;
    margin-right: 1em;
  } */

  .new__assign {
    width: 90em;
    height: 24em;
    margin-left: 3em;
    margin-right: 1em;
  }
  .new__assign__title {
    font-size: 2em;
  }
  /* .last__reports {
    width: 44em;
    height: 24em;
  } */
  .last__reports__title {
    font-size: 1.5em;
  }
  /* .last__task {
    width: 44em;
    height: 40em;
  } */
  .last__task__head {
    font-size: 1.5em;
  }
  /* .last__task__img {
    height: 26em !important;
    width: 40em !important;
    margin-bottom: 2em;
  } */
  .logout__btn {
    margin-left: 1em;
    font-family: "inter";
    font-weight: 500;
  }

  .menu__email {
    font-size: 12px;
    font-family: "fira sans";
    font-weight: 100;
  }
  .menu__managelink {
    font-size: 14px;
    font-family: "fira sans";
    font-weight: 500;
  }
}

@media screen and (max-width: 1920px) {
  /* .recently__viewed__reader {
    width: 70em;
    height: 52em;
    margin-left: 1.5em;
  } */

  .new__assign {
    width: 70em;
    height: 25em;
    margin-left: 1.5em;
  }
  .new__assign__title {
    font-size: large;
  }

  .last__reports__title {
    font-size: 18px;
  }

  .last__reports {
    width: 25vw;
    height: 38vh;
  }

  .last__task {
    width: 25vw;
    height: 38vh;
  }
  /* .last__task__img {
    height: 14em !important;
    width: 14em !important;
    margin-bottom: 0.5em;
  } */
  .last__task__head {
    font-size: 18px;
  }
  .last__task__title {
    font-size: 15px;
  }
  .view__report {
    width: 8px;
  }
  .logout__btn {
    margin-left: 1em;
  }
  .tasks__assigned {
    width: 56em;
  }
  .tasks__submitted {
    width: 56em;
  }
  .tasks__completed {
    width: 56em;
  }

  .menu__email {
    font-size: 12px;
    font-family: "fira sans";
    font-weight: normal;
  }
  .menu__managelink {
    font-size: 14px;
    font-family: "fira sans";
  }
  .recent_project {
    font-family: "inter";
  }

  .progressbar_completed {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 1840px) {
  .last__reports {
    width: 24vw;
    height: 40vh;
  }
}

@media screen and (max-width: 1780px) {
  .recently__viewed {
    width: 83vw;
    height: 75.5555vh;
    margin-left: 1.5em;
  }

  .recently__viewed__reader {
    width: 56vw;
    height: 80vh;
    margin-left: 1.5em;
  }
  .last__reports {
    width: 25vw;
    height: 40vh;
  }
}

@media screen and (max-width: 1536px) {
  .recently__viewed {
    width: 80vw;
    height: 75.5555vh;
    margin-left: 1.5em;
  }
  .recently__viewed__reader {
    width: 54vw;
    height: 80vh;
    margin-left: 1.5em;
  }
}

@media screen and (max-width: 1536px) {
  /* .recently__viewed__reader {
    width: 52em;
    height: 44.7em;
    margin-left: 1.5em;
  } */

  .new__assign {
    width: 52em;
    height: 22em;
    margin-left: 1.5em;
    margin-top: -0.5em !important;
  }
  .new__assign__title {
    font-size: large;
  }
  .last__reports__title {
    font-size: 13px;
  }
  /* .last__task {
    width: 24em;
    height: 22em;
  } */
  .last__task__img {
    height: 10.5em !important;
    width: 10.5em !important;
    margin-left: 1em;
    margin-bottom: 5px;
  }
  .last__task__head {
    font-size: 15px;
  }
  .last__task__title {
    font-size: 15px;
  }
  .last__reports {
    margin-top: -10px !important;
    /* width: 24em; */
  }
}

@media screen and (max-width: 1440px) {
  /* .recently__viewed__reader {
    width: 46em;
    height: 47.7em;
    margin-left: 1.5em;
  } */

  .new__assign {
    width: 46em;
    height: 22em;
    margin-left: 1.5em;
  }
  .new__assign__title {
    font-size: large;
  }
  /* .last__reports {
    width: 21em;
    height: 18em;
  } */
  .last__reports__title {
    font-size: 1em;
  }
  /* .last__task {
    width: 22em;
    height: 25em;
  } */

  /* .last__reports {
    margin-top: -10px !important;
    width: 22em;
    height: 22em;
  } */
  /* .last__task__img {
    height: 13em !important;
    width: 13em !important;
    margin-left: 1em;
    margin-bottom: 5px;
  } */
  /* .last__task__head {
    font-size: 1em;
  } */
  .last__task__title {
    font-size: 15px;
  }
  .view__report {
    display: none;
  }
  .logout__btn {
    margin-left: 1em;
  }

  .menu__email {
    font-size: 12px;
    font-family: "fira sans";
    font-weight: 100;
  }
  .menu__managelink {
    font-size: 14px;
    font-family: "fira sans";
    font-weight: 500;
  }
}

@media screen and (max-width: 1366px) {
  .recently__viewed__reader {
    width: 52vw;
    height: 80vh;
    margin-left: 1.5em;
  }

  .new__assign {
    width: 45em;
    height: 17em;
    margin-left: 1.5em;
    margin-top: -0.5em !important;
  }
  .new__assign__title {
    font-size: large;
  }
  /* .last__reports {
    width: 14em;
    height: 12em;
  } */
  .last__reports__title {
    font-size: 13px;
  }
  /* .last__task {
    margin-top: 0px !important;
  } */
  .last__task__img {
    height: 7em !important;
    width: 7em !important;
    margin-left: 1em;
    margin-bottom: 5px;
  }
  /* .last__task__head {
    font-size: 15px;
  }
  .last__task__title {
    font-size: 15px;
  } */
  /* .last__reports {
    margin-top: -10px !important;
    width: 20em;
    height: 17em;
  } */
}

@media screen and (max-width: 1280px) {
  .recently__viewed {
    width: 78vw !important;
    height: 75vh !important;
    margin-left: 1.5em;
  }
  .recently__viewed__reader {
    width: 50vw;
    height: 80vh;
    margin-left: 1.5em;
  }

  .new__assign {
    width: 32em;
    height: 17em;
    margin-left: 1.5em;
  }
  .new__assign__title {
    font-size: large;
  }
  /* .last__reports {
    width: 14em;
    height: 12em;
  } */
  .last__reports__title {
    font-size: 13px;
  }
  .last__task {
    margin-top: -4px !important;
  }
  .last__task__head {
    font-size: 15px;
  }
  .last__task__title {
    font-size: 15px;
  }
  .view__report {
    display: none;
  }
  .logout__btn {
    margin-left: 1em;
  }

  .menu__email {
    font-size: 12px;
    font-family: "fira sans";
    font-weight: 600;
  }
  .menu__managelink {
    font-size: 14px;
    font-family: "fira sans";
    font-weight: 500;
  }
}
